import React from 'react';
import './Projects.css';
import { Link } from "react-router-dom";

const Projects = (props) => {
    const project_data=[{title:"COZY RETREATS",thumbnail:'./Panel1/thumbnail.jpg'},
                        {title:"ELECTIC ELEGANCE",thumbnail:'./Panel2/thumbnail.jpg'},
                        {title:"SCULPTURAL SPACES",thumbnail:'./Panel4/thumbnail.jpg'},
                        {title:"ELEGANT INTERIORS",thumbnail:'./Panel3/thumbnail.jpg'},]
  return (
    <div className='projects_section'>
        <h2 className='projects_title'><img src='./resources/featured_projects.svg'/></h2>
        <div className='projects_panel'>
        <Link to={{ pathname: "./info/Agarwal's_Residence", state: { value: project_data[0] } }} className='project_link'><div className='panel_card'>
                <div className='title'>
                    <p>{project_data[0].title}</p>
                    </div>
                <div className='info'><p className='info-text'>Creative handmade art, reflecting theme of the space. Precise use of stellar colours and complimenting combinations</p></div>
                <img src={project_data[0].thumbnail} className='photo'/>
            </div>
            </Link><Link to={"/info/krishnani"} className='project_link'>
            <div className='panel_card'>
                <div className='info'><p className='info-text'>Modernity blended in with practicality. Bringing concepts to life with uncompromising quality</p></div>
                <img src={project_data[1].thumbnail} className='photo'/>
                <div className='title'><p>{project_data[1].title}</p></div>
            </div>
            </Link>
            <Link to={"/info/rspl"} className='project_link'>
            <div className='panel_card'>
                <div className='title'><p>{project_data[2].title}</p></div>
                <div className='info'><p className='info-text'>Client’s message clearly conveyed through flawless design and limited budgeted resources </p></div>
                <img src={project_data[2].thumbnail} className='photo'/>
            </div>
            </Link>
            <Link to={"/info/Lawers_Office"} className='project_link'>
            <div className='panel_card'>
                <div className='info'><p className='info-text'>Using law of design according to the taste of client. Vintage garnish with profession specific design features</p></div>
                <img src={project_data[3].thumbnail} className='photo'/>
                <div className='title'><p>{project_data[3].title}</p></div>
            </div>
            </Link>
            
        </div>
        <Link to="/portfolio"><button className='project_button'>View all</button></Link>
    </div>
  );
};

export default Projects;
