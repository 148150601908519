import React from 'react';
import './Portfolio_card.css';

const Portfolio_Card = (props) => {
  return (
    <div className='Card-Section'>
        <img src={props.project_data.thumbnail} className='photo_port'/>
        <p className='card_heading'>{props.project_data.title}</p>
        <p className='card_desc'>{props.project_data.desc}</p>
    </div>
  );
};

export default Portfolio_Card;

