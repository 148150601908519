import React from 'react';
import { useLocation } from 'react-router-dom';
import './Info.css';

const Info_main = (props) => {
  return (
    <div className='info_content'>
      <div className='info_hero'>
        <div className='info_hero_left'>
          <div className='project_title'>
            <h2>{props.project_data.title}</h2>
          </div>
          <p className='left_desc'>
          {props.project_data.desc}
          </p>
        </div>
        <div className='info_hero_right'>
          <div className='right_bg'></div>
          <img
            src={props.project_data.hero_section}
            className='right_photo'
            alt='Right Photo'
          />
        </div>
      </div>
      <div className='info_desc'>
        <div className='info_desc_top'>
          <div className='info_disp'>
            <img
              src={props.project_data.gallery1}
              className='disp1'
              alt='Disp1'
            />
          </div>
          <div className='info_disp'>
            <img
              src={props.project_data.gallery2}
              className='disp2'
              alt='Disp2'
            />
          </div>
        </div>
        <div className='info_desc_bottom'>
          <div className='info_disp'>
            <img
              src={props.project_data.gallery3}
              className='disp3'
              alt='Disp3'
            />
          </div>
          <div className='info_disp'>
            <img
              src={props.project_data.gallery4}
              className='disp4'
              alt='Disp4'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info_main;
