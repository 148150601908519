import React, { useEffect, useRef } from 'react';
import './Main.css';
import About from '../about/About';
import Projects from '../projects/Projects';
import Contact from '../contact/Contact';


const Main = (props) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        // Extract the hash from the URL
        const hash = window.location.hash;
    
        // Check if the hash exists and if an element with that ID is present
        if (hash) {
          const targetElement = document.querySelector(hash);
    
          // Scroll to the target element
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []); // Run this effect only once on component mount
    
  return (
    <div>
        <div className='Hero'>
            <div className='Hero-left'>
                <div className='Hero-heading'>
                    <img src ="./resources/logo_main.svg" className='main_logo'></img>
                </div>
                <img src="./resources/heroleft.png" className='img-left'/>
            </div>
            <div className='Hero-right'>
                <img src="./resources/heromain.png" className='img-right'/>
                <div className='icons'>
                <a href='https://www.instagram.com/design.kaksh?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' style={{ color: 'inherit', textDecoration: 'none' }}>
                  <img src='/icons/insta.svg' className='socials'/>
                  </a>
            
                <a href='https://www.linkedin.com/company/design-kaksh/' style={{ color: 'inherit', textDecoration: 'none' }}>
                <img src='/icons/linked.svg' className='socials'/>
                </a>
                </div>
            </div>
        </div>
        <About/>
        <Projects/>
        <Contact/>
    </div>
  );
};

export default Main;
