import React from 'react';
import { useParams } from "react-router-dom";
import './Info.css';
import Info_main from './Info_main';

const Info = (props) => {
    const routeParams = useParams();
    const project_data=[{title:"Agarwal's Residence",thumbnail:'./Panel1/thumbnail.jpg',hero_section:"/Panel1/herosection.jpg",gallery1:'/Panel1/gallery1.jpg',gallery2:'/Panel1/gallery2.jpg',gallery3:'/Panel1/gallery3.jpg',gallery4:'/Panel1/gallery4.jpg',desc:"The dining area\’s main material palette was a neutral colour on MDF with grooves forming three arches in the area that was then painted by an artist to highlight the wall. The entry was primarily composed of a dark-toned veneer with neutral colour PU on MDF fluted panel to give it a delicate yet visually appealing look. At night, the entry is highlighted with the use of profile lighting.\n\nClient wanted the dining area to be the centre of attraction and highlight of the whole house. The dining wall had to be the dining room\’s most stunning feature, which was the client\’s top need."},
                        {title:"Krishnani",thumbnail:'./Panel2/thumbnail.jpg',hero_section:"/Panel2/hero.jpg",gallery1:'/Panel2/gallery1.jpg',gallery2:'/Panel2/gallery2.jpg',gallery3:'/Panel2/gallery3.jpg',gallery4:'/Panel2/gallery4.jpg',desc:"This project had several aspects to it, each member of the house had different taste and they wanted their corner of the space designed as per their taste. Cumulative requirement of the whole house included elements like modern design, ascetic feel, conventional design with modern touch, highly practical layout and various unique differentiating factors. So we blended in all these elements to fulfil all the design requirement of all the family members, still maintaining a touch of uniformity in the overall design of the house.The common spaces of the house were designed in such a way that they brought in the highlights of all the spaces which had different theme in the house. This made the common ares of the house a big connecting element."},
                        {title:"Lawers office",thumbnail:'./Panel3/thumbnail.jpg',hero_section:"/Panel3/hero.jpg",gallery1:'/Panel3/gallery1.jpg',gallery2:'/Panel3/gallery2.jpg',gallery3:'/Panel3/gallery3.jpg',gallery4:'/Panel3/gallery4.jpg',desc:"Here, the client’s requirements were very specific. It was to insert profession specific theme in the office. Right from the prominent elements like furniture fittings and lightings to small elements like switches and murals, each of them followed directional theme. To give the space more authentic look without compromising on the usability, modern architectural style was combined with a subtle traditional touch."},
                        {title:"Prabhu Paan",thumbnail:'./Panel4/thumbnail.jpg',hero_section:"/Panel4/hero.jpg",gallery1:'/Panel4/gallery1.jpg',gallery2:'/Panel4/gallery2.jpg',gallery3:'/Panel4/gallery3.jpg',gallery4:'/Panel4/gallery4.jpg',desc:"Task was to fit in lot of appealing and practical requirements in limited budget. Client wanted a spotlight element in the shop, an element which acts as big highlight to grab attention of the customers. A massive 3D pan leaf was designed and made from Medium Density Fibre board and Polyurethane Paint, which was put onto the ceiling, huge and elegant enough to be the highlight of the store.Design of the shop also featured the concept of transparency, under which transparent materials are designed and used in a way that it provides a good display of products in the store making it more attractive for the customers. Proper segmentation of the different sections were made to make the store more organised and completing colours acted as the cheery on the cake."}]
    if(routeParams.id==="Agarwal's_Residence"){return(
  <div className='Info_wrapper'>
    <Info_main project_data={project_data[0]}/>
  </div>
  )}
    else if(routeParams.id==="krishnani"){return(
  <div className='Info_wrapper'>
    <Info_main project_data={project_data[1]}/>
  </div>
  )}else if(routeParams.id==="Lawers_Office"){return(
    <div className='Info_wrapper'>
      <Info_main project_data={project_data[2]}/>
    </div>
    )}else if(routeParams.id==="rspl"){return(
        <div className='Info_wrapper'>
          <Info_main project_data={project_data[3]}/>
        </div>
        )};
};

export default Info;
