import React , { useEffect , useRef  } from 'react';
import './About.css';
import { Parallax, Background } from 'react-parallax';
const About = (props) => {
  const text = (
    <>
      Welcome to DesignKaksh, where we turn imagination into reality in an enhanced and sustainable way. 
      We shape your ideas and aspirations into practical living spaces that reflect inventive artistry. 
      At DesignKaksh, we make living more real and sterling through our creative approach. 
      We place a strong emphasis on client satisfaction, ensuring that your needs and preferences are met with utmost care. 
      We believe in maintaining transparency with our clients and all stakeholders involved in the process.
      <br /><br/>
      Our strong and reliable supply networks and contractors enable us to deliver high-quality results. 
      With proper market research and a deep understanding of the industry, we stay updated to provide you with the latest trends and innovative solutions. 
      We are dedicated to working with you every step of the way to ensure your living space represents your aspirations.
    </>
  );

  return (
    <div className='About-Section'>
      <div className='mission'>
        <h2 className='about-head' id='about_us'>Mission</h2>
        <p className='about-text'>Inspired to shape imagination and turning it into reality in an enhanced and sustainable way.</p>
      </div>
      <div className='vision'>
        <h2 className='about-head'>Vision</h2>
        <p className='about-text'>To make living more real and sterling with inventive artistry.</p>
      </div>
        <div className='about'>
            <h2 className='about-head'> About </h2>
            <p className='about-text' id='about-long'>{text}</p>
        </div>
        <div className='bg'></div>
    </div>
  );
};

export default About;
