import React, { useState } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import "./Navbar.css";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => setMenuOpen(false);

  const scrollToSection = (sectionId) => {
    try {
      const targetSection = document.getElementById(sectionId);

      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
        closeMenu();
      } else {
        console.error(`Section with ID "${sectionId}" not found.`);
      }
    } catch (error) {
      console.error(`Error while scrolling to section: ${error.message}`);
    }
  };

  const preventDefault = (event) => {
    const targetPath = event.currentTarget.getAttribute("to");

    // Check if the event was triggered by a button click
    if (targetPath && targetPath.startsWith("/") && !event.currentTarget.contains(event.nativeEvent.target)) {
      event.preventDefault();
      closeMenu();
    }
  };

  return (
    <nav>
      <div className="navback">
        <RouterLink to="/" className="title" onClick={closeMenu} >
            <div className="company_title">
            <img className="kaksh_nav" src="/resources/kaksh.svg"/>
            </div>
          <img src='/resources/logo.svg' className="logo_svg"/>
        </RouterLink>
      </div>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <RouterLink to="/" onClick={closeMenu}>
            Home
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/portfolio" onClick={closeMenu}>
            Portfolio
          </RouterLink>
        </li>
        <li>
          <NavLink to="/#about_us" onClick={() => scrollToSection("about_us")}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/#contact_us" onClick={() => scrollToSection("contact-us")}>
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
