import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import Portfolio_card from './Portfolio_card';

const Portfolio = (props) => {
    const project_data=[{title:"Agarwal's Residence",thumbnail:'/portfolio/agarwal.png',desc:"Creative handmade art, reflecting theme of the space. Precise use of stellar colours and complimenting combinations"},
    {title:"Krishnani",thumbnail:'/portfolio/krishnani.png',desc:"Modernity blended in with practicality. Bringing concepts to life with uncompromising quality "},
    {title:"Lawers Office",thumbnail:'/portfolio/lawer.png',desc:'Using law of design according to the taste of client. Vintage garnish with profession specific design features'},
    {title:"Prabhu Paan",thumbnail:'/portfolio/prabhu.png',desc:'Client\’s message clearly conveyed through flawless design and limited budgeted resources.'},
    {title:"Corporate House",thumbnail:'/portfolio/corp.png',desc:'Making corporate spaces not so corporate. A subtle touch of comfort around class'},
    {title:"Ramchandani’s Residence",thumbnail:'/portfolio/ram.png',desc:'Understanding design languages, communicating it using fine elements with appealing uniformity.'},
    {title:"Net Logics",thumbnail:'/portfolio/net.png',desc:'Fitting big aspirations into limited space. Prime grade work and task oriented design'},
    {title:"Gala Marvela",thumbnail:'/portfolio/gala.png',desc:'Crafting first impressions. Designing entrances which are constructive and making them stand out visually.'},
    {title:"The Capital Office",thumbnail:'/portfolio/capital.png',desc:'Subtle but cultivate design for the space where ideas, creativity and concepts are originated.',},
    {title:"Orchid Blues",thumbnail:'/portfolio/orchid.png',desc:'Small space portraying big sophistication. Pastel colours, consistent and minimalistic pattern synergised, keeping the space cozy but commodious.',},
    {title:"RSPL Guest House",thumbnail:'/portfolio/rspl.png',desc:'Transformed the space into a sanctuary conveying warmth and charm. Infused every corner with inviting, homely design elements making the guest house a home.',},
    {title:"Jadeja’s Residence",thumbnail:'/portfolio/jadeja.png',desc:'Space showcasing design accents which aligns not only with client’s distinguish taste but also with their personality.',}]

    const [port_panelHeight, setDiv1Height] = useState(0);

  useEffect(() => {
    // Get the height of div1
    const height = document.querySelector('.port_panel').offsetHeight;

    // Set the height of div2 to match div1
    setDiv1Height(height);
  }, []);
  return (
    <div className='Portfolio-Section'>
        <div className='portfolio_top'>
            <h2 className='port_heading'>Portfolio</h2>
            <div className='port_info'>
            <p className='info-header'>Our comprehensive range of services include</p>
            <ul className='info-list'>
              <li> Interior design, Architecture</li>
              <li> Space planning</li>
              <li> Concept development</li>
              <li> 3D visualization</li>
              <li> Material selection</li>
              <li> Project management</li>
              <li> Sustainable design</li>
              <li> Landscape design</li>
              <li> Custom furniture and fixtures</li>
            </ul>
            <p className='info-footer'>DesignKaksh is one-stop solution for all your design needs. Choose DesignKaksh for quality, punctuality, finishing, and value value proposition. We don't just work for our clients—we work with our clients to create exceptional living spaces.</p>
            </div>
        </div>
        <div className='portfolio_bottom' >
            <div className='bottom_bg' style={{ height: `${port_panelHeight}px`}}></div>
            <div className='port_panel'>
            <div className='port_hero'>
            <Portfolio_card project_data={project_data[0]}/>
            <Portfolio_card project_data={project_data[1]}/>
            <Portfolio_card project_data={project_data[2]}/>
            </div><div className='port_hero'>
            <Portfolio_card project_data={project_data[3]}/>
            <Portfolio_card project_data={project_data[4]}/>
            <Portfolio_card project_data={project_data[5]}/>
            </div><div className='port_hero'>
            <Portfolio_card project_data={project_data[6]}/>
            <Portfolio_card project_data={project_data[7]}/>
            <Portfolio_card project_data={project_data[8]}/>
            </div><div className='port_hero'>
            <Portfolio_card project_data={project_data[9]}/>
            <Portfolio_card project_data={project_data[10]}/>
            <Portfolio_card project_data={project_data[11]}/>
            </div>
            </div>
        </div>
        <hr className="line"/>
     </div>
  );
};

export default Portfolio;
