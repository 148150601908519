import React from 'react';
import './Footer.css';

const Footer = (props) => {
  return (
    <div className="Footer_Section">
      <div className="Logo_footer footer_box">
        <div>
          <img src="/resources/logo-footer.svg" className="footer_logo" alt="Footer Logo" />
        </div>
      </div>
      <div className="Address_footer footer_box">
        <p className="footer_heading">Address</p>
        <p className="footer_text">B-1105, The Capital, Science City Road, Sola, Ahmedabad -380060</p>
      </div>
      <div className="Contact_footer footer_box">
        <p className="footer_heading"><b>Contact</b></p>
        <p className="footer_text">
          designkakshahmedabad@gmail.com
          <br />
          +91 940 847 3496
        </p>
      </div>
      <div className="Socials_footer footer_box">
        <p className="footer_heading">Socials</p>
        <a 
          href="https://www.instagram.com/design.kaksh?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" 
          style={{ color: 'inherit', textDecoration: 'none' }}>
          <p className="footer_text">Instagram</p>
        </a>
        <a href="https://www.linkedin.com/company/design-kaksh/" 
          style={{ color: 'inherit', textDecoration: 'none' }}>
          <p className="footer_text">LinkedIn</p>
        </a>
      </div>
    </div>
  );
};

export default Footer;
