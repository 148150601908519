import React, { useRef } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';

const Contact = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_343ddlj', 'template_6o639j4', form.current, {
        publicKey: 'QrzpSFWO0pDZFh9v0',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          alert('Submitted successfully');
          form.current.reset();  // Reset the form after submission
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className='Contact-Section'>
      <h2 className='contact_header' id='contact-us'>Customer Experience Loop</h2>
      <p className='contact_header' id="loop-text">
        Introducing our customer grievance redressal system, the "Customer Experience Loop." This feature allows you to easily voice any complaints or issues you may have with our products or services. Rest assured that we are committed to responding to your concerns within 24 hours. Depending on the nature of the problem, we aim to resolve it as quickly as possible, ensuring your satisfaction. Additionally, this system helps us learn, adapt and improve based on your valuable feedback. The ultimate goal of this loop is to provide you with the best overall experience.
      </p>
      <h2 className='contact_header' id='contact-us'>Let's Work Together</h2>
      <form className='contact_form' onSubmit={sendEmail} ref={form}>
        <div className='form_element'>
          <div className='form_sub'>
            <label htmlFor="fname" className='input_text'>First name:</label>
            <input type="text" id="fname" name="First_Name" className='inputfield'/>
          </div>
          <div className='form_sub'>
            <label htmlFor="lname" className='input_text'>Last name:</label>
            <input type="text" id="lname" name="Last_Name" className='inputfield'/>
          </div>
        </div>
        <div className='form_element'>
          <div className='form_sub'>
            <label htmlFor="email" className='input_text'>Email:</label>
            <input type="text" id="email" name="Email" className='inputfield'/>
          </div>
          <div className='form_sub'>
            <label htmlFor="phone" className='input_text'>Phone:</label>
            <input type="text" id="phone" name="Phone" className='inputfield'/>
          </div>
        </div>
        <div className='form_element'>
          <div className='form_sub2'>
            <label htmlFor="feedback" className='input_text'>Feedback:</label>
            <input type="text" id="feedback" name="Feedback" className='inputfield2'/>
          </div>
        </div>
        <button className='project_button' id='contact_button' type="submit" value="Send">Submit</button>
      </form>
    </div>
  );
};

export default Contact;
