import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './navbar/Navbar';
import Main from './main/Main';
import Footer from './footer/Footer';
import Portfolio from './portfolio/Portfolio';
import Info from './project_page/Info';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/info/:id" element={<Info />} />
    </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
